import React, { useState, useEffect } from 'react';
// import { Link } from "react-router-dom";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  MemoryRouter,
} from 'react-router-dom';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MailIcon from '@mui/icons-material/Mail';
import UpcomingOutlinedIcon from '@mui/icons-material/UpcomingOutlined';
import LeakAddOutlinedIcon from '@mui/icons-material/LeakAddOutlined';
import InsightsIcon from '@mui/icons-material/Insights';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import Tooltip from '@mui/material/Tooltip';
import { Outlet } from 'react-router-dom';
// import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { makeStyles, createStyles } from '@mui/material/styles';
import { Auth } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { deepOrange } from '@mui/material/colors';

const drawerWidth = 220;

const settings = ['Profile', 'Account', 'Logout'];

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(
  itemProps,
  ref,
) {
  return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
}

function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to } = props;
  return (
    <li>
      <ListItem component={Link} to={to}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

export default function LabTemplate() {
  const theme = useTheme();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [userFirstname, setUserFirstname] = useState('');
  const [userLastname, setUserLastname] = useState('');
  const [userAvatarString, setUserAvatarString] = useState();

  useEffect(() => {
    try {
      setError(null);
      setLoading(true);

      Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(user => {
        setUsername(user.username);
        setUserFirstname(user.attributes.given_name);
        setUserLastname(user.attributes.family_name);
        setUserAvatarString(user.attributes.given_name.charAt(0).toUpperCase().concat(user.attributes.family_name.charAt(0).toUpperCase()))
        // TBD
      }).catch(err => setError(err));
    }
    catch (e) {
      
      if (typeof e === "string") {
        e.toUpperCase() // works, `e` narrowed to string
    } else if (e instanceof Error) {
        // setError(e) // works, `e` narrowed to Error
    }
    }
    finally {
      setLoading(false);
    }
  }, []);


  return (
    <Box sx={{ display: 'grid', width: '100%' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar >
          {/* <Link style={{ textDecoration: 'none', color: 'white' }} to="/"> */}
          <Typography variant="navBarVag" noWrap component="div">
            veracity
            <span style={{color:'#767586'}}>Lab</span>
          </Typography>
          {/* </Link> */}
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Box>
          <Box sx={{ ml:3, flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar sx={{bgcolor: deepOrange[500]}}>
                  {userAvatarString}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      
      <Container component="main" maxWidth="xl" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Outlet />
      </Container>
    </Box>
  );
}