import React, { useState, useEffect } from 'react';
import '../../../App.css';
import { Stack, Container } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import ModuleCard from "../components/lab/moduleCard";
import GreetingCard from "../components/lab/greetingCard";
import Button from '@mui/material/Button';

import alarmImg from "../assets/img/alarms.png"
import rbiImg from "../assets/img/rbi.png"

import { Auth } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { green, orange, deepOrange } from '@mui/material/colors';
declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

function Lab() {

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [userFirstname, setUserFirstname] = useState('');
  const [userLastname, setUserLastname] = useState('');
  const [userDisplayName, setUserDisplayName] = useState('');
  const [userAvatarString, setUserAvatarString] = useState('');

  useEffect(() => {
    try {
      setError(null);
      setLoading(true);

      Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(user => {
        setUsername(user.username);
        if (user.attributes.given_name && user.attributes.family_name){
          setUserFirstname(user.attributes.given_name);  
          setUserLastname(user.attributes.family_name);
          setUserAvatarString(user.attributes.given_name.charAt(0).toUpperCase().concat(user.attributes.family_name.charAt(0).toUpperCase()))
          setUserDisplayName(user.attributes.given_name.concat(" ").concat(user.attributes.family_name))
        } else {
          setUserAvatarString(user.username.charAt(0).toUpperCase())
          setUserDisplayName(user.username)
        }
        // TBD
      }).catch(err => setError(err));
    }
    catch (e) {
      
      if (typeof e === "string") {
        e.toUpperCase() // works, `e` narrowed to string
    } else if (e instanceof Error) {
        // setError(e) // works, `e` narrowed to Error
    }
    }
    finally {
      setLoading(false);
    }
  }, []);

  return (
    <Box sx={{ width: '100%', flex: 1, pr: 2, pl: 2}}>
      <Grid container sx={{pr: 10, pl: 10, justifyContent: 'center'}} spacing={2} rowSpacing={1}>
        <Grid sm={8}>
          <Stack sx={{pt: 3}} alignItems="flex-start" justifyContent="flex-end">
            <Typography sx={{ml: 40, mb:0}} variant="body2Vag" color="#1B2064">
              Powered by <span style={{color: '#ED4B48'}}>AIE</span>
            </Typography>
            <Typography variant="h2VagBold" color="#1B2064" gutterBottom>
              welcome to veracity<span style={{color:'#767586'}}>Lab</span>
            </Typography>
          </Stack>
        </Grid>
        <Grid sm={4}>
          <Grid sx={{pt: 5}} container justifyContent="flex-end">
            <Stack sx={{pr: 2}} alignItems="start" justifyContent="flex-start">
              <Typography  variant="body1" color="text.secondary" sx={{pl: 0, pb:0}}>
                {userDisplayName}
              </Typography>
              <Typography  variant="body1" color="text.secondary">
                <Button variant="text" sx={{pl:0, pt:0, mt: 0}} onClick={() => { signOut(); }}>
                  Log Out
                </Button>
              </Typography>
            </Stack>
            <Avatar sx={{bgcolor: deepOrange[500]}}>
              {userAvatarString}
            </Avatar>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={1} columns={15} sx={{pt: 3}}>
        <Grid sm={3} md={3} sx={{height: 200}}>
          <GreetingCard />
        </Grid>
        <Grid container columns={12} md={12} spacing={1}>
            <Grid sm={6} md={3}>
              <ModuleCard title="Met Accounting" moduleLink='metaccounting' imgName={alarmImg} description="Status: Under Development"></ModuleCard>
            </Grid>

            <Grid sm={6} md={3}>
              <ModuleCard title="Alarms" moduleLink='lab/alarmmanagement' imgName={alarmImg} description="Status: Under Development"></ModuleCard>
            </Grid>

            <Grid sm={6} md={3}>
                <ModuleCard title="Unplanned Losses" moduleLink='unplannedlosses' imgName={rbiImg} description="Status: Under Development"></ModuleCard>
            </Grid>

            <Grid sm={6} md={3}>
              <ModuleCard title="Watchkeeping" moduleLink='watchkeeping' imgName={alarmImg} description="Status: Under Development"></ModuleCard>
            </Grid>

            <Grid sm={6} md={3}>
              <ModuleCard title="Chemical Optimizer" moduleLink='lab/chemicaloptimizer' imgName={rbiImg} description="Status: Under Development"></ModuleCard>
            </Grid>

            <Grid sm={6} md={3}>
              <ModuleCard title="Pipeline Safety Legislation" moduleLink='lab/chemicaloptimizer' imgName={rbiImg} description="Status: Complete"></ModuleCard>
            </Grid>

            <Grid sm={6} md={3}>
              <ModuleCard title="Chemical Inventory" moduleLink='lab/chemicaloptimizer' imgName={rbiImg} description="Status: Hold"></ModuleCard>
            </Grid>
            
            <Grid sm={6} md={3}>
              <ModuleCard title="Well Annulus Pressure" moduleLink='lab/chemicaloptimizer' imgName={rbiImg} description="Status: Initiation"></ModuleCard>
            </Grid>

            <Grid sm={6} md={3}>
              <ModuleCard title="Piping Analytics" moduleLink='lab/chemicaloptimizer' imgName={rbiImg} description="Status: Under Development"></ModuleCard>
            </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Lab;
