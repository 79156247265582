import {useMap} from "react-leaflet";
import React, {useEffect, useState} from "react";
import L from "leaflet/";

export default function HeatMap({lopc}) {
    const map = useMap()
    let layers = [];
    const [lopcLayers, setLopcLayers] = useState(null);
    useEffect(() => {

        map && lopc.features.map((p) => {
            layers.push(L.heatLayer(p.geometry.coordinates, {
                radius: 20,
                blur: 10,
                scaleRadius: true,
                useLocalExtrema: true
            }));
        });
        const layerGroup = L.layerGroup(layers);
        setLopcLayers(layerGroup)

        map.addLayer(layerGroup);

    }, []);

    map.on('overlayremove', onOverlayRemove);
    map.on('overlayadd', onOverlayAdd);

    function onOverlayAdd(e) {
        if (e?.name === 'LOPC HeatMap') {
            lopcLayers && map.addLayer(lopcLayers);
        }
    }

    function onOverlayRemove(e) {
        if (e?.name === 'LOPC HeatMap') {
            lopcLayers && map.removeLayer(lopcLayers);
        }
    }

    return (<>
        </>
    )
}