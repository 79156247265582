// import * as React from 'react';
import axios from "axios";
import React, {useEffect, useRef, ReactElement, useCallback} from "react";
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import OilBarrelOutlinedIcon from '@mui/icons-material/OilBarrelOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import {LeafletMapComp} from './LeafletMapComp';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from "highcharts/highcharts-more";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { Amplify, Auth} from 'aws-amplify';

Auth.configure({
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_APP_CLIENT_ID
  })

HighchartsMore(Highcharts);

const chartOptions = {

    chart: {
        type: "waterfall",
    },
    xAxis: {
        type: "category",
        lineWidth: 0,
        title: {text: "Category"}
    },
    title: {
        text: "2023 Unplanned Facility Losses - YTD by Category",
        align: "center"
    },
    yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {text: "Losses (MBD)"}
    },

    legend: {
        enabled: false
    },

    tooltip: {
        pointFormat: "<b>{point.y:,.2f}</b> mbd"
    },

    series: [
        {
            animation: false,
            color: "#4170C4",
            lineWidth: 0,
            data: [
                {
                    name: "ESP Trips & S/Ds",
                    y: 10.7,
                    color: "green",
                    opacity:"0.5"
                },
                {
                    name: "Back Pressure",
                    y: 2.5
                },
                {
                    name: "Choke",
                    y: 0.3
                },
                {
                    name: "Facility Constraint",
                    y: 1.5
                },
                {
                    name: "Facility Leak",
                    y: 1.7,
                    color: "green",
                    opacity:"0.5"
                },
                {
                    name: "Optimization",
                    y: 0.9
                },
                {
                    name: "Flowline Leaks",
                    y: 4.2,
                    color: "green",
                    opacity:"0.5"
                },
                {
                    name: "Facility Other",
                    y: 0.2
                },
                {
                    name: "Process Upset",
                    y: 2.0
                },
                {
                    name: "SSV",
                    y: 0.9
                },
                {
                    name: "Facility Theft",
                    y: 0.0
                },
                {
                    name: "Unplanned Pitstops",
                    y: 0.7
                },
                {
                    name: "Others",
                    y: 5.8
                },
                {
                    name: "Total",
                    isIntermediateSum: true,
                }
            ],
            dataLabels: {
                enabled: true,
                style: {
                    fontWeight: "bold"
                }
            },
            pointPadding: 0
        }
    ],
    credits: false
};

const barChartOptions = {
    chart: {
        type: 'bar'
    },
    title: {
        text: '2023 Unplanned Losses - Overall TD Average',
        align: 'center'
    },
    xAxis: {
        categories: ['YTD Target (MBD)', 'YTD Actual (MBD)'],
        type: "category",
        title: {
            text: null
        },
        gridLineWidth: 1,
        lineWidth: 0
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Losses (MBD)',
            align: 'middle'
        },
        labels: {
            overflow: 'justify'
        },
        gridLineWidth: 0
    },
    tooltip: {
        valueSuffix: ' MBD'
    },
    plotOptions: {
        type: 'bar',
        dataLabels: {
            enabled: true
        },
        series: {
            centerInCategory: true
        }
    },
    legend: false,
    credits: {
        enabled: false
    },
    series: [{
        name: 'Target (MBD)',
        data: [['YTD Target (MBD)',42.0]]
    }, {
        name: 'Actual (MBD)',
        data: [['YTD Actual (MBD)',31.7]]
    }]
}


const failureDistributionChartOptions = {

    chart: {
        type: "column",
        zoomType: 'xy'
    },
    title: {
        text: '',
        align: 'left'
    },
    xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            crosshair: true
    },
    yAxis: [{ 
        labels: {
            format: '{value}°C',
            style: {
                color: "#000000"
            }
        },
        title: {
            text: 'Temperature',
            style: {
                color: "#000000"
            }
        },
        opposite: true

    }, { 
        gridLineWidth: 0,
        title: {
            text: 'Rainfall',
            style: {
                color: "#000000"
            }
        },
        labels: {
            format: '{value} mm',
            style: {
                color: "#000000"
            }
        }

    }, { 
        gridLineWidth: 0,
        title: {
            text: 'Sea-Level Pressure',
            style: {
                color: "#000000"
            }
        },
        labels: {
            format: '{value} mb',
            style: {
                color: "#000000"
            }
        },
        opposite: true
    }],
    tooltip: {
        valueSuffix: ' million liters'
    },
    plotOptions: {
        column: {
            stacking: 'nornal'
        }
    },
    series: [{
        type: 'column',
        name: 'No planned Replacement - active Mitigagtion',
        yAxis: 1,
        color: "#ffff00",
        data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]
    }, {
        type: 'column',
        name: 'Planned Replacement - Active mitigation',
        yAxis: 1,
        color: "#00a86b",
        data: [101, 76, 115.9, 115.5, 112.3, 109.5, 109.6, 101.2, 113.1, 116.9, 118.2, 116.7]
    }, {
        type: 'column',
        name: 'Planned Replacement - No active mitigation',
        yAxis: 1,
        color: "#cb410b" ,
        data:  [117.0, 76.9, 119.5, 114.5, 118.2, 121.5, 125.2, 126.5, 123.3, 118.3, 113.9, 49.6]
    },{
        type: 'column',
        name: 'No planned replacement - No active mitigation',
        yAxis: 1,
        color: "#fdae61" ,
        data: [17.0, 66.9, 119.5, 114.5, 118.2, 121.5, 125.2, 126.5, 123.3, 118.3, 113.9, 49.6]
        
    }, 
    {
        type: 'column',
        name: 'No planned Replacement - active Mitigagtion',
        yAxis: 2,
        color: "#ffff00",
        data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]
    }, {
        type: 'column',
        name: 'Planned Replacement - Active mitigation',
        yAxis: 2,
        color: "#00a86b",
        data: [101, 76, 115.9, 115.5, 112.3, 109.5, 109.6, 101.2, 113.1, 116.9, 118.2, 116.7]
    }, {
        type: 'column',
        name: 'Planned Replacement - No active mitigation',
        yAxis: 2,
        color: "#cb410b" ,
        data:  [117.0, 76.9, 119.5, 114.5, 118.2, 121.5, 125.2, 126.5, 123.3, 118.3, 113.9, 49.6]
    },{
        type: 'column',
        name: 'No planned replacement - No active mitigation',
        yAxis: 2,
        color: "#fdae61" ,
        data: [17.0, 66.9, 119.5, 114.5, 118.2, 121.5, 125.2, 126.5, 123.3, 118.3, 113.9, 49.6]
        
    },
    {
        type: 'spline',
        name: 'Average',
        color : "#000000",
        data: [349.9, 271.5, 506.4, 529.2, 500.0, 576.0, 495.6, 500.5, 506.4, 594.1, 595.6, 554.4],
        marker: {
            lineWidth: 1,
            lineColor: "#000000" ,
            fillColor: 'black'
        }
    },
    { 
        type: 'spline',
        name: 'Average',
        color : "#000000",
        data: [247.0, 216.9, 219.5, 214.5, 218.2, 221.5, 25.2, 126.5, 123.3, 118.3, 113.9, 800.6],
        marker: {
            lineWidth: 2,
            lineColor: "#000000",
            fillColor: 'black'
        }
    }, 
    {
        type: 'pie',
        align : "right",
        name: 'Total',
        data: [{
            name: 'No planned Replacement - active Mitigagtion',
            y: 145,
            color: "#ffff00", // 2020 color
            dataLabels: {
                enabled: true,
                distance: -50,
                format: '{point.total} M',
                style: {
                    fontSize: '15px'
                }
            }
        }, {
            name: 'Planned Replacement - Active mitigation',
            y: 74,
            color: "#00a86b" // 2021 color
        }, {
            name: 'No planned replacement - No active mitigation',
            y: 134,
            color: "#cb410b" // 2022 color
        }, {
            name: 'Planned Replacement - No active mitigation',
            y: 98,
            color: "#fdae61" // 2022 color
        }],
        center: [25, 15],
        size: 100,
        innerSize: '0%',
        showInLegend: false,
        dataLabels: {
            enabled: false
        }
    }]
};



function createData(
loss: string,
  nr: number,
  sr: number,
  total: number,
  typicalEvent: string,
  improvementPlan: string
) {
    return {loss, nr, sr, total, typicalEvent, improvementPlan};
}

const rows = [
    createData('ESP Trips & S/Ds', 3.5, 7.3, 10.7, "Genset trips / VSD", "Meantime to repair, repeat offenders, winterization/summerization"),
    createData('Back Pressure', 2.0, 0.5, 2.5, "Train Moves / Flex Lines", "Backpressure issues in NRrationalised over the week - current backpressure loss is 0 mbd"),
    createData('Choke', 0.1, 0.2, 0.3, "Choke & SSV", "Improved surveillance by Subsurface, DOF and ACE Team"),
    createData('Facility Constraint', 1.6, 0.0, 1.5, "", ""),
    createData('Facility Constraint', 0.0, 2.2, 1.7, "Underground leak at Mishrif", "Working with maintenance on long term strategy"),
    createData('Optimization', 0.8, 0.1, 0.9, "High wc wells shut in", "Review with SS future plans for R-194 and R-164"),
    createData('Flowline Leaks', 2.3, 1.9, 4.2, "LOPC in flow lines to stations", "Corrosion Inhibition program / TMS & Proactive Flowline Replacement / Materials Selection Strategy being developed for step change in materials of construction."),
    createData('Facility Other', 0.2, 0.0, 0.2, "", ""),
    createData('Process Upset', 2.0, 0.1, 2.0, "Crash valves blockages", "Station stability and improved oversight (DCS ACE)"),
    createData('SSV', 0.4, 0.5, 0.9, "Failure of actuator /valves control panels", "Improvement plans for SSVs to change the type of valve used and control panels if required"),
    createData('Facility Theft', 0.0, 0.0, 0.0, "Theft", "Removal of Gen/VSD from offline wells completed. CCTV installation for OPF - 60% complete. Expand plans to include CCTV for ROO Ops and ROO Security use"),
    createData('Unplanned Pitstops', 0.6, 0.1, 0.7, "", ""),
    createData('Others', 5.5, 0.4, 5.8, "Constrained wells on DS3 and DS4", "DS3 FWKO online - all DS3 wells online DS4: Debottlenecking plans being progresed"),
];

var jwt = ""
const accessToken = Auth.currentSession().then(data => {
  let accessToken = data.getAccessToken()
  jwt = accessToken.getJwtToken()
});
const config = {
  headers: { Authorization: `Bearer ${jwt}` }
};

const baseURL = "http://127.0.0.1:8080/unplannedlosses/"

export default function UnplannedLosses(props: HighchartsReact.Props) {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [post, setPost] = React.useState(null);

    React.useEffect(() => {
      const accessToken = Auth.currentSession().then(data => {
        let accessToken = data.getAccessToken()
        jwt = accessToken.getJwtToken()
      });
      const config = {
        headers: { Authorization: `Bearer ${jwt}` }
      };
      axios.get(baseURL, config).then((response) => {
        setPost(response.data);
      }).catch(function (error) {console.log("Error:", error)});
      }, []);

    return (
        <Box component="main" sx={{flexGrow: 1, p: 3}}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    sx={{display: 'flex', alignItems: 'center'}}
                    color="inherit"
                    href="/"
                >
                    <Typography sx={{display: 'flex', alignItems: 'bottom'}} color="text.secondary">
                        <HomeOutlinedIcon sx={{mr: 0.5}} fontSize="small"/>
                        Veracity
                    </Typography>
                </Link>

                <Link
                    underline="hover"
                    sx={{display: 'flex', alignItems: 'center'}}
                    color="inherit"
                    href="/lab"
                >
                    <Typography sx={{display: 'flex', alignItems: 'bottom'}} color="text.secondary">
                        <ScienceOutlinedIcon sx={{mr: 0.5}} fontSize="small"/>
                        Lab
                    </Typography>
                </Link>

                <Typography sx={{display: 'flex', alignItems: 'bottom'}} color="text.primary">
                    <OilBarrelOutlinedIcon sx={{mr: 0.5}} fontSize="small"/>
                    Unplanned Losses
                </Typography>
            </Breadcrumbs>
            <Divider sx={{marginBottom: 2}}/>
            <br/>
            <h1 style={{fontFamily: 'VAGRoundedLight', fontSize: '32px', marginTop: 0}}>Unplanned Losses</h1>

            <Card sx={{backgroundColor:'#F8FAFC'}}>
                <CardContent>
                    <Typography sx={{display: 'flex', alignItems: 'bottom', color: "black",}} variant="h6Vag">
                        Field Visualization
                    </Typography>
                    {/*<hr style={{border:'0.2px solid #A9A6A6'}}/>*/}

                    <LeafletMapComp/>
                    <br/>
                    <Typography sx={{display: 'flex', alignItems: 'bottom', color: "black", pt: 3}} variant="h6Vag">
                        Unplanned Facility Loss Performance - YTD (Mar 23)
                    </Typography>
                    {/*<hr/>*/}
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={barChartOptions}
                        ref={chartComponentRef}
                        {...props}
                    />
                    <br/>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                        ref={chartComponentRef}
                        {...props}
                    />
                    <br/>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 650}} size="small" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Loss</TableCell>
                                    <TableCell align="center">NR (MBD)</TableCell>
                                    <TableCell align="center">SR (MBD)</TableCell>
                                    <TableCell align="center">Total (MBD)</TableCell>
                                    <TableCell align="center">Typical Event</TableCell>
                                    <TableCell align="center">Improvement Plan</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row,i) => (
                                    <TableRow
                                        key={i}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell align="left" component="th" scope="row">{row.loss}</TableCell>
                                        <TableCell align="center">{row.nr}</TableCell>
                                        <TableCell align="center">{row.sr}</TableCell>
                                        <TableCell align="center">{row.total}</TableCell>
                                        <TableCell align="left">{row.typicalEvent}</TableCell>
                                        <TableCell align="left">{row.improvementPlan}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
            <div>
            <Typography sx={{display: 'flex', alignItems: 'bottom', color: "black", pt: 3}} variant="h6Vag">
                            Failure Distribution - by Pipeline
                        </Typography>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={failureDistributionChartOptions}
                            ref={chartComponentRef}
                            {...props}
                        />
            </div>
        </Box>
    );
}