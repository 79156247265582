// import * as React from 'react';
import React, {useEffect, useRef, ReactElement, useCallback} from "react";
import { Outlet } from "react-router-dom";
import Link from '@mui/material/Link';
import { Link as domLink } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { AppBar } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';

import Collapse from "@mui/material/Collapse";


// Icons
import StarBorder from '@mui/icons-material/StarBorder';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SummarizeIcon from '@mui/icons-material/Summarize';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { faEnvelope, faFlask, faIndustry } from '@fortawesome/free-solid-svg-icons'
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons'

import {faChartArea, faChartLine, faMound} from '@fortawesome/free-solid-svg-icons';


const drawerWidth = 240;

export default function MetAccounting() {

  const [open, setOpen] = React.useState(true);
  const [metAccountingMenuOpen, setMetAccountingMenuOpen] = React.useState(true);
  const [dashboardsMenuOpen, setDashboardsMenuOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleMetAccountingClick = () => {
    setMetAccountingMenuOpen(!metAccountingMenuOpen)
  }

  const handleDashboardsClick = () => {
    setDashboardsMenuOpen(!dashboardsMenuOpen)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            VeracityAPM
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Divider />
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {/* Production Data */}
            </ListSubheader>
          }
        >
          
          <ListItemButton onClick={handleDashboardsClick}>
            <ListItemIcon>
              <QueryStatsIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboards" />
              {dashboardsMenuOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          
          <Collapse in={dashboardsMenuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding> 
            <ListItemButton key="Production" sx={{pl:4}} component={domLink} to="/lab/metaccounting/dashboards/production">
              <ListItemIcon>
                <FontAwesomeIcon icon={faChartArea} />
              </ListItemIcon>
              <ListItemText primary="Production" />
            </ListItemButton>

            <ListItemButton key="Performance" sx={{pl:4}} component={domLink} to="/lab/metaccounting/dashboards/performance">
              <ListItemIcon>
                <FontAwesomeIcon icon={faChartLine} />
              </ListItemIcon>
              <ListItemText primary="Performance" />
            </ListItemButton>
          </List>
          </Collapse>

          <ListItemButton key="Planning" sx={{p:0}} component={domLink} to="/lab/metaccounting/planning">
            <ListItemButton>
              <ListItemIcon>
                <CalendarMonthIcon />
              </ListItemIcon>
              <ListItemText primary="Planning" />
            </ListItemButton>
          </ListItemButton>

          <ListItemButton onClick={handleMetAccountingClick}>
            <ListItemIcon>
              <AccountBalanceIcon />
            </ListItemIcon>
            <ListItemText primary="Met Accounting" />
              {metAccountingMenuOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={metAccountingMenuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding> 
            <ListItemButton key="Mining" sx={{pl:4}} component={domLink} to="/lab/metaccounting/productiondata">
              <ListItemIcon>
                <FontAwesomeIcon icon={faMound} />
              </ListItemIcon>
              <ListItemText primary="Mining" />
            </ListItemButton>

            <ListItemButton key="Processing" sx={{pl:4}} component={domLink} to="/lab/metaccounting/processing">
              <ListItemIcon>
              <FontAwesomeIcon icon={faIndustry} />
              </ListItemIcon>
              <ListItemText primary="Processing" />
            </ListItemButton>
            
            <ListItemButton key="Leaching" sx={{pl:4}} component={domLink} to="/lab/metaccounting/leaching">
              <ListItemIcon>
              <FontAwesomeIcon icon={faFlask} />
              </ListItemIcon>
              <ListItemText primary="Leaching" />
            </ListItemButton>

            <ListItemButton key="Grades" sx={{pl:4}} component={domLink} to="/lab/metaccounting/grades">
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Grades" />
            </ListItemButton>

            <ListItemButton key="Reagents" sx={{pl:4}} component={domLink} to="/lab/metaccounting/reagents">
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Reagents" />
            </ListItemButton>

            <ListItemButton key="WaterBalance" sx={{pl:4}} component={domLink} to="/lab/metaccounting/waterbalance">
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Water Balance" />
            </ListItemButton>

          </List>
          </Collapse>
          
          <ListItemButton key="MetReport" sx={{p:0}} component={domLink} to="/lab/metaccounting/metreport">
            <ListItemButton>
              <ListItemIcon>
                <SummarizeIcon />
              </ListItemIcon>
              <ListItemText primary="Met Report" />
            </ListItemButton>
          </ListItemButton>
        </List>
      </Drawer>
      <Box sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
        <Toolbar />
        <Outlet />
        {/* <Typography paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
          enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
          imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
          Convallis convallis tellus id interdum velit laoreet id donec ultrices.
          Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
          adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
          nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
          leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
          feugiat vivamus at augue. At augue eget arcu dictum varius duis at
          consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
          sapien faucibus et molestie ac.
        </Typography>
        <Typography paragraph>
          Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
          eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
          neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
          tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
          sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
          tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
          gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
          et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
          tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.
        </Typography> */}
      </Box>
    </Box>
  )

  // return (
  //   <div>
  //     <Box  sx={{ display: 'flex' }}>
  //       <Drawer
  //         variant="permanent"
  //         sx={{
  //           width: drawerWidth,
  //           mt: 20,
  //           flexShrink: 0,
  //           [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
  //         }}
  //       >
  //         <Box sx={{ mt: '64px' }}>
  //         <List>
  //           <ListItemButton key="ProductionData" sx={{p:0}} component={domLink} to="/lab/metaccounting/productiondata">
  //             <ListItemButton>
  //               <ListItemIcon>
  //                 <InboxIcon />
  //               </ListItemIcon>
  //               <ListItemText primary="Production Data" />
  //             </ListItemButton>
  //           </ListItemButton>
  //           <ListItemButton key="MetReport" sx={{p:0}} component={domLink} to="/lab/metaccounting/metreport">
  //             <ListItemButton>
  //               <ListItemIcon>
  //                 <InboxIcon />
  //               </ListItemIcon>
  //               <ListItemText primary="Met Report" />
  //             </ListItemButton>
  //           </ListItemButton>
  //         </List>
  //         </Box>
  //         <Divider />
  //       </Drawer>
      

  //       <Box component="main" sx={{flexGrow: 1}}>
  //         <Breadcrumbs sx={{p: 0}} aria-label="breadcrumb">
  //           <Link
  //               underline="hover"
  //               sx={{display: 'flex', alignItems: 'center'}}
  //               color="inherit"
  //               href="/"
  //           >
  //               <Typography sx={{display: 'flex', alignItems: 'bottom'}} color="text.secondary">
  //                   <HomeOutlinedIcon sx={{mr: 0.5}} fontSize="small"/>
  //                   Veracity
  //               </Typography>
  //           </Link>

  //           <Link
  //               underline="hover"
  //               sx={{display: 'flex', alignItems: 'center'}}
  //               color="inherit"
  //               href="/lab"
  //           >
  //               <Typography sx={{display: 'flex', alignItems: 'bottom'}} color="text.secondary">
  //                   <ScienceOutlinedIcon sx={{mr: 0.5}} fontSize="small"/>
  //                   Lab
  //               </Typography>
  //           </Link>

  //           <Typography sx={{display: 'flex', alignItems: 'bottom'}} color="text.primary">
  //               <AppRegistrationIcon sx={{mr: 0.5}} fontSize="small"/>
  //               Met Accounting
  //           </Typography>
  //         </Breadcrumbs>
  //         <Divider sx={{mb: 2}} />
  //         <Outlet />
  //       </Box>
  //     </Box>
  //   </div>
  // )
}