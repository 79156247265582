import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


export default function GreetingCard() {

  const cardTheme = createTheme({
    palette: {
      background: {
        paper: "#004892",
      },
    },
    typography: {
      fontFamily: 'VAG Rounded Light',
      h5: {
        color: 'white',
        fontWeight: 900,
        fontSize: '36px'
      },
      body2: {
        color: 'white',
        fontSize: '14',
        fontWeight: 300

      },
    },
});

return (
  <Card sx={{ background: 'linear-gradient(246.82deg, #1B2064 46.7%, #004892 146.7%)', borderRadius: '10px'}}>
    <CardContent>
      <Typography gutterBottom variant="h5Vag" component="div">
        Good Day
      </Typography>
      <Typography variant="body2Vag" component="div">
        VeracityLab pioneering the future, today.
      </Typography>
    </CardContent>
  </Card>
)}