import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import {Checkbox, InputAdornment, ListItemIcon, ListSubheader, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import React, {SetStateAction, useMemo, useState} from "react";

export default function MUIMultiSelect(props: {
    selected: string[]
    handleChange: (event: SelectChangeEvent<string[]>) => void
    setSelected: React.Dispatch<SetStateAction<string[]>>
    options: { id?: number, name: string }[]
}) {
    const {selected, handleChange, setSelected, options} = props;
    const [searchText, setSearchText] = useState("");
    const handleClearClick = () => {
        setSelected([]);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 350,
            },
        },
    };

    const isAllSelected = selected.length > 0 && selected.length === options.length;
    const containsText = (text: string, searchText: string) =>
        text &&  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

    const displayedOptions = useMemo(
        () => options.filter((option) => containsText(option.name, searchText)),
        [searchText, options]
    );
    return (
        <Select
            label="Tags"
            multiple
            value={selected}
            onChange={handleChange}
            onClose={() => setSearchText("")}
            renderValue={(selected) => selected.join(", ")}
            endAdornment={<IconButton sx={{display: selected.length ? "" : "none"}}
                                      onClick={handleClearClick}><ClearIcon/></IconButton>}
            MenuProps={MenuProps}
        >
            <ListSubheader>
                <TextField
                    size="small"
                    // Autofocus on textfield
                    autoFocus
                    placeholder="Type to search..."
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon/>
                            </InputAdornment>
                        )
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                            // Prevents autoselecting item while typing (default Select behaviour)
                            e.stopPropagation();
                        }
                    }}
                />
            </ListSubheader>
            {options && options.length ?
                <MenuItem value="all" classes={{root: ""}}>
                    <ListItemIcon>
                        <Checkbox
                            classes={{indeterminate: 'black'}}
                            checked={isAllSelected}
                            indeterminate={
                                selected.length > 0 && selected.length < options.length
                            }
                        />
                    </ListItemIcon>
                    <ListItemText primary="Select All"/>
                </MenuItem>:<MenuItem value="">
                <em>None</em>
            </MenuItem>
            }
            {displayedOptions?.map(option => (
                <MenuItem key={option.id || option.name} value={option.name}>
                    <ListItemIcon>
                        <Checkbox checked={selected.indexOf(option.name) > -1}/>
                    </ListItemIcon>
                    <ListItemText primary={option.name}/>
                </MenuItem>
            ))
            }
        </Select>
    )
}