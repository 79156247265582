export const subProjects=[
    {
        "id": 22,
        "name": "Janubia"
    },
    {
        "id": 35,
        "name": "DS05"
    },
    {
        "id": 36,
        "name": "DS04"
    },
    {
        "id": 40,
        "name": "Qurainat"
    },
    {
        "id": 41,
        "name": "DS01"
    },
    {
        "id": 42,
        "name": "Shamiya"
    },
    {
        "id": 44,
        "name": "Source Water & Water Injection Facilities"
    },
    {
        "id": 45,
        "name": "DS02"
    },
    {
        "id": 46,
        "name": "SIDS"
    },
    {
        "id": 48,
        "name": "NIDS"
    },
    {
        "id": 49,
        "name": "Markezia"
    },
    {
        "id": 52,
        "name": "DS03"
    },
    {
        "id": 53,
        "name": "M-Shamiya"
    },
    {
        "id": 54,
        "name": "M-Qurainat"
    },
    {
        "id": 55,
        "name": "Ratqa"
    },
    {
        "id": 155,
        "name": "QA1"
    },
    {
        "id": 156,
        "name": "QA2"
    },
    {
        "id": 157,
        "name": "CPS1"
    },
    {
        "id": 158,
        "name": "CPS2"
    },
    {
        "id": 159,
        "name": "CPS3"
    },
    {
        "id": 160,
        "name": "CPS4"
    },
    {
        "id": 161,
        "name": "CPS5"
    },
    {
        "id": 162,
        "name": "CPS6"
    },
    {
        "id": 163,
        "name": "CPS7"
    }
];