import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import moment from "moment";


export default function GreetingCard() {

return (
  <div>
  <Card sx={{ height: 708, background: 'linear-gradient(246.82deg, #1B2064 46.7%, #004892 146.7%)', borderRadius: '10px'}}>
    <CardContent>
      <Typography gutterBottom variant="h5Vag" component="div">
        Good Day
      </Typography>
      <Typography gutterBottom variant="h6Vag" component="div" >
        Introducing <Link style={{ textDecoration: 'underline', color: 'white' }} to="/lab">veracityLab</Link>
      </Typography>
      
      <Typography variant="body2Vag" component="div" sx={{pb:3}}>
        veracityLab - the newest innovation hub from AIE! We are thrilled to provide our customers with a dedicated space to collaborate and explore solutions to their most pressing business challenges. At veracityLab, we believe in the power of innovation and creativity to drive growth and success. Our team of experts are at the forefront of cutting-edge technologies and are committed to delivering exceptional results for our clients.
      </Typography>
      
      <Typography variant="h6Vag" component="div" sx={{pt: 30}}>
          {moment().format("dddd, DD MMM")}
      </Typography>
      <Typography variant="h5Vag" component="div">
        {moment().format("HH:MM")}
      </Typography>
    </CardContent>
  </Card>
  </div>
)
}