import './App.css';
import { Routes, Route } from "react-router-dom"

import { Amplify, Auth} from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';


import Landing from './veracity-modules/landing/Landing';
import Lab from './veracity-modules/lab/pages/Lab';
import UnplannedLosses from './veracity-modules/lab/pages/UnplannedLosses/UnplannedLosses';
import WatchKeeping from './veracity-modules/lab/pages/Watchkeeping/Watchkeeping';

import MetAccounting from './veracity-modules/lab/pages/MetAccounting/MetAccounting';
import ProductionData from './veracity-modules/lab/pages/MetAccounting/ProductionData';
import MetReport from './veracity-modules/lab/pages/MetAccounting/MetReport';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import LabTemplate from "../src/veracity-modules/lab/components/common/LabTemplate";
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';


Auth.configure({
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_APP_CLIENT_ID
})


Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_APP_CLIENT_ID
  }
})

const db_host = process.env.DB_HOST_NAME;

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1Vag: true;
    h1VagBold: true;
    h2VagBold: true;
    h5VagBold: true;
    h5Vag: true;
    h6VagBold: true;
    h6Vag: true;
    descriptionVagBold: true;
    body2Vag: true;
    navBarVag: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  h1Vag: React.CSSProperties;
  h1VagBold: React.CSSProperties;
}

const theme = createTheme({
  typography: {
    h1Vag: {
      fontWeight: 200,
      fontFamily: 'VAGRound',
      fontSize: '3.3125rem',
      lineHeight: '1.15em',
    },
    h1VagBold: {
      fontWeight: 900,
      fontFamily: 'VAGRound',
      fontSize: '3.3125rem',
      lineHeight: '1.15em',
    },
    h2VagBold: {
      fontWeight: 900,
      fontFamily: 'VAGRound',
      fontSize: '60px',
      lineHeight: '1.15em',
    },
    h5VagBold: {
      fontWeight: 900,
      fontFamily: 'VAGRound',
      fontSize: '3.5rem',
      lineHeight: '1.15em',
    },
    h5Vag: {
      fontFamily: 'VAGRoundedLight',
      color: 'white',
      fontWeight: 900,
      fontSize: '24px'
    },
    h6VagBold: {
      fontFamily: 'VAGRound',
      fontWeight: 400,
      fontSize: "1.75em"
    },
    navBarVag: {
      fontFamily: 'VAGRound',
      fontWeight: 400,
      fontSize: "1.95em"
    },
    h6Vag: {
      fontFamily: 'VAGRoundedLight',
      color: 'white',
      fontWeight: 900,
      fontSize: '24px'
    },
    descriptionVagBold: {
      fontFamily: 'VAGRound',
      fontWeight: 300,
      fontSize: "1.5em"
    },
    body2Vag: {
      fontFamily: 'VAGRoundedLight',
      fontSize: '16px',
      fontWeight: 100,
      color: 'white',
    }
  }  as ExtendedTypographyOptions,
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          background: 'linear-gradient(180deg, #06205D 100%, #111737 100%)'
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        dense: {
          height: 68,
          minHeight: 68
        }
      }
    }
  },
});

function App() {
  return (
    <div>
      <Authenticator>
        {({ signOut, user }) => (
          <Box sx={{width: '100%', overflow: 'auto'}}>
            <div>            
            </div>
            <ThemeProvider theme={theme}>
              <Routes>
                <Route path="/" element={ <Landing/> } />
                <Route path="/lab" element={ <Lab/>} />
                <Route path="/lab" element={ <LabTemplate/> }>
                  <Route path="/lab/unplannedlosses" element={ <UnplannedLosses/>} />
                  <Route path="/lab/watchkeeping" element={ <WatchKeeping/>} />
                </Route>
                <Route path="/lab/metaccounting" element={ <MetAccounting/>} >
                  <Route path="/lab/metaccounting/productiondata" element={ <ProductionData/>} />
                  <Route path="/lab/metaccounting/metreport" element={ <MetReport/>} />
                </Route>
              </Routes>
            </ThemeProvider>
          </Box>
        )}
      </Authenticator>
    </div>
  );
}

export default App;

