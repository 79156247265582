import Box from "@mui/material/Box";
import {SwipeableDrawer} from "@mui/material";
import React, {useEffect, useState} from "react";
import pipelineMeta from "./static-json/pipeline.json"

export default function Drawer(props: {
    open: boolean,
    onClose: (open: boolean) => void
    onOpen: (open: boolean) => void
    onClick: (open: boolean) => void
    onKeyDown: (open: boolean) => void
    currentItem: any
}) {

    const [meta, setMeta]=useState<any>(null);
    useEffect(()=>{
        if (props.currentItem && "veracity_pipeline_id" in props.currentItem){
          const item=  pipelineMeta.filter(r=>r.id===props.currentItem.veracity_pipeline_id);
            item && setMeta(item[0]);
        }
    },[props.currentItem]);

    return (<SwipeableDrawer
        anchor={'right'}
        open={props.open}
        onClose={() => props.onClose(false)}
        onOpen={() => props.onOpen(true)}
    >
        <Box
            sx={{width: 350, mt: 8, ml: 2}}
            role="presentation"
            onClick={() => props.onClick(false)}
            onKeyDown={() => props.onKeyDown(false)}
        >
            {props.currentItem && <>
                {props.currentItem.WELL_NAME && <>
                    <h4>Well: {props.currentItem.WELL_NAME}</h4>
                    <img style={{width: '340px'}} className={'img-banner'} src={require("../../assets/img/well1.png")}/>
                </>}
                {props.currentItem.veracity_pipeline_id && meta.name && <>
                    <h4>Pipeline: {meta.name}</h4>
                    <img style={{width: '340px'}} className={'img-banner'} src={require("../../assets/img/pipeline1.png")}/>
                </>}
                {props.currentItem.FACILITY_N && <>
                    <h4>Degassing Station: {props.currentItem.FACILITY_N}</h4>
                    <img style={{width: '340px'}} className={'img-banner'} src={require("../../assets/img/degassing.jpg")}/>
                </>}
            </>}
            <p>More stuff about to come here...</p>
        </Box>
    </SwipeableDrawer>)
}