import React, {useRef, useState, useMemo, useEffect} from 'react';
import 'leaflet/'
import {
    MapContainer,
    TileLayer,
    GeoJSON,
    LayersControl,
    ImageOverlay,
    WMSTileLayer, Circle
} from "react-leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import L, {Layer} from 'leaflet/';
import DS from "./static-json/DS_Boundary_converted.json";
import Pipelines from "./static-json/veracity_pipelines.json";
import OilPipelines from "./static-json/Oil_Pipelines_converted.json";
import Wells from "./static-json/wells.json";
import WaterPipelines from "./static-json/water_pipelines.json";
import FieldBoundary from "./static-json/field_boundary.json";
import HCA from "./static-json/veracity_hca.json";
import LOPC from "./static-json/lopc_events.json";
import BasraPipelines from "./static-json/basra_pipelines.json";
import {FeatureCollection} from "geojson";
import Button from "@mui/material/Button";
import Drawer from "./Drawer";
import "leaflet.heat";
import "./UnplannedLosses.css";
import FilterListIcon from '@mui/icons-material/FilterList';
import Filter from "./Filter";
import HeatMap from "./HeatMap";
import pipelineMeta from "./static-json/pipeline.json"

const containerStyle = {
    width: '100%',
    height: '88vh'
};

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;


const hashString = (str: string) => {
    var hash = 0,
        i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};
export const LeafletMapComp = () => {
    const map = useRef(null)

    // @ts-ignore
    const [oilPipelines, setOilPipelines] = useState<FeatureCollection>(OilPipelines);
    // @ts-ignore
    const [allPipelines, setAllPipelines] = useState<FeatureCollection>(Pipelines);
    // @ts-ignore
    const [ds, setDs] = useState<FeatureCollection>(DS);
    // @ts-ignore
    const [wells, setWells] = useState<FeatureCollection>(Wells);
    // @ts-ignore
    const [waterPipelines, setWaterPipelines] = useState<FeatureCollection>(WaterPipelines);
    // @ts-ignore
    const [fieldBoundary, setFieldBoundary] = useState<FeatureCollection>(FieldBoundary);
    // @ts-ignore
    const [lopc, setLOPC] = useState<FeatureCollection>(LOPC);
    // @ts-ignore
    const [pipelines, setPipelines] = useState<FeatureCollection>(Pipelines);
    // @ts-ignore
    const [hca, setHCA] = useState<FeatureCollection>(HCA);
    // @ts-ignore
    const [basraPipelines, setBasraPipelines] = useState<FeatureCollection>(BasraPipelines)
    const [pipelineSelected, setPipelineSelected] = useState<string[]>([]);
    const [open, setOpen] = React.useState(false);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [currentItem, setCurrentItem] = React.useState<any>(null);

    const handleOpen = () => setOpen(true);

    const clickHandler = () => {

        toggleDrawer(true)
    };

    const gtt2 = () => {
        const buttonEl = document.getElementById("popup-button-id");

        if (buttonEl) {
            buttonEl.removeEventListener("click", clickHandler);
        }
    };

    function onEachFeature(feature: any, layer: Layer) {
        let popupData = '';
        if (feature.properties && feature.properties.veracity_pipeline_id) {//Pipelines
            const entity=pipelineMeta.filter(r=>r.id===feature.properties.veracity_pipeline_id)?.[0]
            popupData = entity ? `Name: <a id="popup-button-id" style="cursor: pointer">${entity.name}</a>`:"";
        } else if (feature.properties && feature.properties.WELL_NAME) {//Wells
            popupData = `Name: <a id="popup-button-id" style="cursor: pointer">${feature.properties.WELL_NAME}</a>`
        } else if (feature.properties && feature.properties.FACILITY_N) {//DS
            popupData = `Facility Location: <a id="popup-button-id" style="cursor: pointer">${feature.properties.FACILITY_N}</a>`
        }
        popupData && layer
            .addEventListener("popupopen", function (e: any) {
                setCurrentItem(e.popup._source.feature.properties)
                const buttonEl = document.getElementById("popup-button-id");

                if (buttonEl) {
                    buttonEl.addEventListener("click", clickHandler);
                }
            })
            .addEventListener("popupclose", gtt2)
            .bindPopup(popupData).openPopup();
    }

    function onEachHCAFeature(feature: any, layer: Layer) {
        let popupData = '';
        if (feature.properties && feature.properties.PIPE_NAME) {//Pipelines
            popupData = `Name: <a id="popup-button-id" style="cursor: pointer">${feature.properties.PIPE_NAME}</a>`;
        } else if (feature.properties && feature.properties.WELL_NAME) {//Wells
            popupData = `Name: <a id="popup-button-id" style="cursor: pointer">${feature.properties.WELL_NAME}</a>`
        } else if (feature.properties && feature.properties.FACILITY_N) {//DS
            popupData = `Facility Location: <a id="popup-button-id" style="cursor: pointer">${feature.properties.FACILITY_N}</a>`
        }
        popupData && layer
            .addEventListener("popupopen", function (e: any) {
                setCurrentItem(e.popup._source.feature.properties)
                const buttonEl = document.getElementById("popup-button-id");

                if (buttonEl) {
                    buttonEl.addEventListener("click", clickHandler);
                }
            })
            .addEventListener("popupclose", gtt2)
            .bindPopup(popupData).openPopup();

    }

    const toggleDrawer = (open: boolean) => {
        setOpenDrawer(open);
    };

    // @ts-ignore
    const setIcon = ({properties}, latlng) => {
        return L.circleMarker(latlng, {color: 'red', radius: 6});
    };
    // @ts-ignore
    const setHCAIcon = ({properties}, latlng) => {
        if (properties.featureType && properties.featureType === 'Circle') {

            return L.circle(latlng, properties.radius, {color: properties.color});
        } else if (properties.featureType && properties.featureType === 'Polygon') {

            return L.polygon(latlng, {color: 'orange'});
        }
        return L.circleMarker(latlng, {color: 'red', radius: 2});
    };// @ts-ignore
    const lopFailureCoordinates = (coords) => {
        return new L.LatLng(coords[0][0], coords[0][1]);

    };

    // var osm = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //     maxZoom: 19,
    //     attribution: '© OpenStreetMap'
    // });

    // var baseMaps = {
    //     "OpenStreetMap": osm,
    // };
    const latLngBounds = L.latLngBounds([[40.799311, -74.118464], [40.68202047785919, -74.33]]);

    const wmsOptions = {
        layers: 'Landsat-8',
        transparent: true,
        format: 'image/png'
    }

    const layerParams = useMemo(() => {
        return {hello: 'world'};
    }, []);

    return (
        <>
            <MapContainer ref={map}
                          center={[30.438889032647904, 47.34751085026624]}
                          zoom={10}
                          scrollWheelZoom={true}
                          style={containerStyle}
            >


                <LayersControl position="topright">
                    <LayersControl.BaseLayer name="NASA">
                        <TileLayer
                            url="https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/BlueMarble_ShadedRelief_Bathymetry/default//EPSG3857_500m/{z}/{y}/{x}.jpeg"
                            attribution="&copy; NASA Blue Marble, image service by OpenGeo"
                            maxNativeZoom={10}
                        />
                    </ LayersControl.BaseLayer>

                    <LayersControl.BaseLayer checked name="Google">
                        <TileLayer
                            url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        />
                    </ LayersControl.BaseLayer>

                    <LayersControl.BaseLayer name="OSM">
                        <TileLayer
                            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>

                    <LayersControl.BaseLayer name="Landsat">
                        <WMSTileLayer
                            url="https://kade.si/cgi-bin/mapserv?"
                            params={wmsOptions}
                        />
                    </LayersControl.BaseLayer>

                    <LayersControl.Overlay name="Image">
                        <ImageOverlay
                            url='https://landsat.arcgis.com/arcgis/rest/services/LandsatGLS/MS/ImageServer'
                            bounds={latLngBounds}
                        />
                    </LayersControl.Overlay>

                    {fieldBoundary &&
                        <LayersControl.Overlay name="Field Boundary">
                            <GeoJSON key={hashString(JSON.stringify(fieldBoundary))}
                                     data={fieldBoundary}
                                     style={{color: 'yellow', fillOpacity: 0.05, weight: 1}}

                                     onEachFeature={onEachFeature}/>
                        </LayersControl.Overlay>
                    }
                    {pipelines &&
                        <LayersControl.Overlay name="Pipelines">
                            <GeoJSON key={hashString(JSON.stringify(pipelines))}
                                     data={pipelines}
                                     style={{color: 'black', fillOpacity: 0.50, weight: 2}}
                                     onEachFeature={onEachFeature}/>
                        </LayersControl.Overlay>
                    }
                    {ds &&
                        <LayersControl.Overlay name="De-Gassing Stations">
                            <GeoJSON key={hashString(JSON.stringify(ds))}
                                     data={ds}
                                     onEachFeature={onEachFeature}
                                     style={{color: 'orange', fillOpacity: 1, weight: 1}}
                            />
                        </LayersControl.Overlay>
                    }
                    {wells &&
                        <LayersControl.Overlay name="Wells">
                            <GeoJSON key={hashString(JSON.stringify(wells))}
                                     pointToLayer={setIcon}
                                     data={wells}
                                     style={{color: 'gray', fillOpacity: 0.10, weight: 1}}
                                     onEachFeature={onEachFeature}/>
                        </LayersControl.Overlay>
                    }
                    {waterPipelines &&
                        <LayersControl.Overlay name="Water Pipelines">
                            <GeoJSON key={hashString(JSON.stringify(waterPipelines))}
                                     data={waterPipelines}
                                     style={{color: 'blue', fillOpacity: 0.10, weight: 1}}
                                     onEachFeature={onEachFeature}/>
                        </LayersControl.Overlay>
                    }
                    {hca &&
                        <LayersControl.Overlay name="HCAs">

                            <GeoJSON key={hashString(JSON.stringify(hca))}
                                     data={hca}
                                     style={{color: 'purple', fillOpacity: 0.10, weight: 1}}
                                     pointToLayer={setHCAIcon}
                                     onEachFeature={onEachHCAFeature}/>
                        </LayersControl.Overlay>
                    }
                    {lopc &&
                        <LayersControl.Overlay checked name="LOPC HeatMap" >
                            <Circle color={'none'} center={[50.5, 30.5]} radius={0} />
                            <HeatMap lopc={lopc}/>
                        </LayersControl.Overlay>
                    }
                    {lopc &&
                        <LayersControl.Overlay name="LOPC Failures">

                            <GeoJSON key={hashString(JSON.stringify(lopc))}
                                     data={lopc}
                                     coordsToLatLng={lopFailureCoordinates}
                                     style={{color: 'purple', fillOpacity: 0.10, weight: 1}}
                            />
                        </LayersControl.Overlay>
                    }
                    {oilPipelines &&
                        <LayersControl.Overlay name="Oil Pipelines">
                            <GeoJSON key={hashString(JSON.stringify(oilPipelines))}
                                data={oilPipelines}
                                style={{color: 'red', fillOpacity: 0.10, weight: 1}}
                                onEachFeature={onEachFeature}/>
                        </LayersControl.Overlay>
                    }

                    {basraPipelines && 
                        <LayersControl.Overlay name="Basra Pipelines">
                            <GeoJSON key={hashString(JSON.stringify(basraPipelines))}
                                 data={basraPipelines}
                                 style={{color: 'blue', fillOpacity: 0.10, weight: 1}}
                                 onEachFeature={onEachFeature}/>
                        </LayersControl.Overlay>
                    }

                    <div className={'leaflet-top leaflet-right'}>
                        <div className="leaflet-control leaflet-bar">
                            <Button className='custom-filter-btn' onClick={handleOpen} variant="outlined">
                                <FilterListIcon/>
                            </Button>
                            {/* <FullscreenControl/> */}
                        </div>
                    </div>
                    {/*<FullscreenControl  position="topright"  />*/}

                </LayersControl>

                {/*{isLoaded && <FullscreenControl position="topright" forceSeparateButton={true} title="Make full screen" />}*/}
            </MapContainer>
            <Filter setPipelineSelected={setPipelineSelected}
                    open={open}
                    onClose={() => setOpen(false)}
                    pipelineSelected={pipelineSelected}
                    allPipelines={allPipelines}
                    pipelines={pipelines}
                    setPipelines={setPipelines}

            />
            <Drawer
                currentItem={currentItem}
                open={openDrawer}
                onClose={setOpenDrawer}
                onOpen={setOpenDrawer}
                onClick={setOpenDrawer}
                onKeyDown={setOpenDrawer}
            />
        </>
    )
}
// export default LeafletMapComp