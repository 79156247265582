import React, { useState, useEffect } from 'react';

import { Stack, Container } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import ModuleCard from "./components/moduleCard";
import GreetingCard from "./components/greetingCard";
import Button from '@mui/material/Button';

import inspectionImg from "./assets/img/inspection.png"
import wellImg from "./assets/img/wells.png"
import rbiImg from "./assets/img/rbi.png"
import emsImg from "./assets/img/ems.png"
import ccmImg from "./assets/img/ccm.png"
import anomalyImg from "./assets/img/anomaly.png"
import pipelineImg from "./assets/img/pipeline.png"
import apmImg from "./assets/img/apm.png"

import { Amplify, Auth} from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth'

import { green, orange, deepOrange } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

const region: String = String(process.env.REACT_APP_AWS_REGION)
const userPoolId: String = String(process.env.REACT_APP_USER_POOL_ID)
const userPoolWebClientId: String = String(process.env.REACT_APP_USER_POOL_APP_CLIENT_ID)

function Landing() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [userFirstname, setUserFirstname] = useState('');
  const [userLastname, setUserLastname] = useState('');
  const [userDisplayName, setUserDisplayName] = useState('');
  const [userAvatarString, setUserAvatarString] = useState('');

  

  useEffect(() => {
    console.log("Use Effect Being Called!")
    Auth.currentSession()
      .then((data) => console.log(data))
      .catch((err) => console.log(err));

    try {
      setError(null);
      setLoading(true);

      Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(user => {
        setUsername(user.username);
        if (user.attributes.given_name && user.attributes.family_name){
          setUserFirstname(user.attributes.given_name);  
          setUserLastname(user.attributes.family_name);
          setUserAvatarString(user.attributes.given_name.charAt(0).toUpperCase().concat(user.attributes.family_name.charAt(0).toUpperCase()))
          setUserDisplayName(user.attributes.given_name.concat(" ").concat(user.attributes.family_name))
        } else {
          setUserAvatarString(user.username.charAt(0).toUpperCase())
          setUserDisplayName(user.username)
        }
      }).catch(err => setError(err));
    }
    catch (e) {
      
      if (typeof e === "string") {
        e.toUpperCase() // works, `e` narrowed to string
    } else if (e instanceof Error) {
        // setError(e) // works, `e` narrowed to Error
    }
    }
    finally {
      setLoading(false);
    }
  }, []);

  

  return (
    <Box sx={{ flexGrow: 1, pr:2, pl:2 }}>
      {/* Veracity Banner */}
      <Grid container sx={{pr: 10, pl: 10}} spacing={2} rowSpacing={1}>
        <Grid sm={8}>
          <Stack sx={{pt: 3}} alignItems="flex-start" justifyContent="flex-end">
            <Typography variant="body2Vag" color="#1B2064" sx={{ml: 40, mb:0}}>
              Powered by <span style={{color: '#ED4B48'}}>AIE</span>
            </Typography>
            <Typography variant="h2VagBold" color="#1B2064" gutterBottom>
              welcome to veracity
            </Typography>
          </Stack>
        </Grid>
        <Grid sm={4}>
          <Grid sx={{pt: 5}} container justifyContent="flex-end">
            <Stack sx={{pr: 2}} alignItems="start" justifyContent="flex-start">
              <Typography  variant="body1" color="text.secondary" sx={{pl: 0, pb:0}}>
                {userDisplayName}
              </Typography>
              <Typography  variant="body1" color="text.secondary">
                <Button variant="text" sx={{pl:0, pt:0, mt: 0}} onClick={() => { signOut(); }}>
                  Log Out
                </Button>
              </Typography>
            </Stack>
            <Avatar sx={{bgcolor: deepOrange[500]}}>
              {userAvatarString}
            </Avatar>
          </Grid>
        </Grid>
      </Grid>
    
      {/* Veracity Hub Grid */}
      <Grid container spacing={1} columns={15} sx={{pt: 3}}>
        <Grid sm={3} md={3} sx={{height: 200}}>
          <GreetingCard />
        </Grid>
        <Grid container columns={12} md={12} spacing={1}>
            <Grid sm={3} md={3}>
              <ModuleCard title="Inspection" moduleLink='inspection' imgName={inspectionImg} description="The digital solution for work-scope development and field data capture"></ModuleCard>
            </Grid>

            <Grid sm={3} md={3}>
              <ModuleCard title="RBI" moduleLink='rbi' imgName={rbiImg} description="Modern, standardized & integrated approach to pressure systems RBI"></ModuleCard>
            </Grid>

            <Grid sm={3} md={3}>
              <ModuleCard title="CCM" moduleLink='ccm' imgName={ccmImg} description="Holistic corrosion, chemical & flow assurance management"></ModuleCard>
            </Grid>

            <Grid sm={3} md={3}>
              <ModuleCard title="Pipeline" moduleLink='pipeline' imgName={pipelineImg} description="Comprehensive & assured pipeline Integrity management"></ModuleCard>
            </Grid>

            <Grid sm={3} md={3}>
              <ModuleCard title="Wells" moduleLink='wells' imgName={wellImg} description="The optimal well integrity management system"></ModuleCard>
            </Grid>

            <Grid sm={3} md={3}>
              <ModuleCard title="EMS" moduleLink='ems' imgName={emsImg} description="Moving industry forward in Environmental & Sustainability management"></ModuleCard>
            </Grid>

            <Grid sm={3} md={3}>
              <ModuleCard title="Anomaly" moduleLink='anomaly' imgName={anomalyImg} description="The complete defect management solution"></ModuleCard>
            </Grid>

            <Grid sm={3} md={3}>
              <ModuleCard title="APM" moduleLink='apm' imgName={apmImg} description="Enabling Total Asset Performance Management"></ModuleCard>
            </Grid>
        </Grid>
      </Grid>

    </Box>
  );
}

export default Landing;
