import React, { useEffect, useRef, ReactElement, useCallback } from "react";
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import {LeafletMapComp} from './LeafletMapComp';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import OilBarrelOutlinedIcon from '@mui/icons-material/OilBarrelOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

export default function WatchKeeping() {

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/"
        >
          <Typography sx={{ display: 'flex', alignItems: 'bottom' }} color="text.secondary">
            <HomeOutlinedIcon sx={{ mr: 0.5 }} fontSize="small" />
            Veracity
          </Typography>
        </Link>
        
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/lab"
        >
          <Typography sx={{ display: 'flex', alignItems: 'bottom' }} color="text.secondary">
            <ScienceOutlinedIcon sx={{ mr: 0.5 }} fontSize="small"/>
            Lab
          </Typography>
        </Link>
        
        <Typography sx={{ display: 'flex', alignItems: 'bottom' }} color="text.primary">
          <QueryStatsIcon sx={{ mr: 0.5}} fontSize="small" />
          WatchKeeping
        </Typography>
      </Breadcrumbs>
      <Divider sx={{marginBottom: 2}} />
      <LeafletMapComp />
    </Box>
  )

}