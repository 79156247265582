import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";

interface ModuleDetails {
    imgName: string;
    title: string;
    description: string;
    moduleLink: string;
  }


export default function ModuleCard(props: ModuleDetails) {
  
  return (
    <Card sx={{height: 256, background: 'linear-gradient(246.82deg, #1B2064 46.7%, #004892 146.7%)', borderRadius: '10px'}}>
      <CardMedia
        sx={{ height: 158 }}
        image={props.imgName}
      />
      <CardContent>
        <Link style={{ textDecoration: 'none', color: 'white' }} to={props.moduleLink}>
          <Typography gutterBottom variant="h5Vag" component="div">{props.title}</Typography>
        </Link>
        <Typography variant="body2Vag">
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  )
}