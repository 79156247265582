import React, {useEffect, useRef, useState, useMemo} from 'react';
import 'leaflet/'
import {MapContainer, TileLayer, GeoJSON, LayersControl, FeatureGroup, Polygon, ImageOverlay, WMSTileLayer} from "react-leaflet";


export const LeafletMapComp = () => {
  
  const containerStyle = {
    width: '100%',
    height: '88vh'
  };
  const map = useRef(null)

  return (
    <MapContainer ref={map}
                  center={[30.438889032647904, 47.34751085026624]} 
                  zoom={10} 
                  scrollWheelZoom={true}
                  style={containerStyle}
                  >

                  <LayersControl position="topright">
                    <LayersControl.BaseLayer checked name="Google">
                        <TileLayer
                            url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        />
                    </ LayersControl.BaseLayer>
                  </LayersControl>

    </MapContainer>
  )
}