import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";

interface ModuleDetails {
    imgName: string;
    title: string;
    description: string;
    moduleLink: string;
  }

let link_path = 'https://apm.aie-veracity.com/projects/88/executive-dashboard'

export default function ModuleCard(props: ModuleDetails) {

  const theme = createTheme({
    typography: {
      fontFamily: 'VAGRoundedLight',
    },
  });

  const cardTheme = createTheme({
    palette: {
      background: {
        paper: "#004892",
        
      },
    },
    typography: {
      h5: {
        fontFamily: 'VAGRound',
        color: 'white'
      },
      body2: {
        color: 'white',
        fontFamily: 'VAGRoundedLight',
        fontSize: '14',
        fontWeight: 300,
      },
    },
});

if (props.moduleLink === "apm") {
  link_path = 'https://apm.aie-veracity.com/projects/88/executive-dashboard'
  } else {
    link_path = props.moduleLink
  }

  return (      
    <Card sx={{height: 350, background: 'linear-gradient(246.82deg, #1B2064 46.7%, #004892 146.7%)', borderRadius: '10px'}}>
      <CardMedia
        sx={{ height: 150 }}
        image={props.imgName}
      />
      <CardContent>
        <Link style={{ textDecoration: 'none', color: 'white' }} to={link_path}>
          <Typography gutterBottom variant="h5Vag" component="div">veracity<span style={{color: '#767586'}}>{props.title}</span></Typography>
        </Link>
        <Typography variant="body2Vag">
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  )
}