import {Card, FormControlLabel, FormLabel, Modal, OutlinedInput, Radio, RadioGroup} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {subProjects} from "./filterData";
import MUIMultiSelect from "../../components/common/MUIMultiSelect";
import CardContent from "@mui/material/CardContent";
import React, {SetStateAction, useEffect, useMemo, useState} from "react";
import pipelineMeta from "./static-json/pipeline.json"
import {PipelineMetaType} from "../../types";

export default function Filter(props: {
    open: boolean
    onClose: () => void
    setPipelineSelected: React.Dispatch<SetStateAction<any>>
    setPipelines: React.Dispatch<SetStateAction<any>>
    pipelineSelected: string[]
    allPipelines: any
    pipelines: any
}) {
    const {pipelineSelected, setPipelines, setPipelineSelected, allPipelines, pipelines} = props;
    const [subProjectId, setSubProjectId] = useState<string>("")

    useEffect(() => {
        setPipelineSelected(pipelineMeta.map((r: any) => r.name));
    }, [pipelineMeta])
    const style = {
        position: 'absolute' as 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        borderRadius: 5,
        p: 2,
    };
    function compare( a:any, b:any ) {
        if ( a.name < b.name ){
            return -1;
        }
        if ( a.name > b.name ){
            return 1;
        }
        return 0;
    }

    const getPipelineOptions = () => {
            return pipelineMeta?.sort( compare )
    }

    const uniquePipelineNames = useMemo(() => getPipelineOptions(), [pipelines])

    const handlePipelineFilterChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: {value},
        } = event;


        if (value[value.length - 1] === "all" && uniquePipelineNames?.length != pipelineSelected.length) {

            setPipelineSelected(uniquePipelineNames.map((r: any) => r.name));
            setPipelines((prevState: any) => ({...prevState, features: allPipelines?.features}));
            return;
        } else if (uniquePipelineNames?.length === pipelineSelected.length) {
            setPipelineSelected([]);
            setPipelines((prevState: any) => ({...prevState, features: []}));
            return;
        }

        let filtered: any = [];
        if (typeof value === 'object' && value.length > 0) {

            filtered = pipelineMeta.filter((f: any) => value && value.includes(f.name));
        } else {
            filtered = pipelineMeta;
        }
        setPipelineSelected(filtered.map((r: any) => r.name));
        const pipelineIds=filtered.map((r: any) => r.id);
        const filteredFeatures=allPipelines.features.filter((f: any) => pipelineIds && pipelineIds.includes(f.properties.veracity_pipeline_id));

        setPipelines((prevState: any) => ({...prevState, features: filteredFeatures}));
    }
    const subProjectChange = (event: SelectChangeEvent) => {
        const value = event.target.value;
        let filtered: number[] = [];
        if (value) {
            filtered = pipelineMeta.filter(r => r.sub_project_id === +value).map(r => r.id);
        }
        setSubProjectId(value);
        const filteredPipesMeta = allPipelines?.features.filter((r: any) => filtered.length && filtered.includes(r.properties.veracity_pipeline_id))
        setPipelines((prevState: any) => ({...prevState, features: filteredPipesMeta}));
        setPipelineSelected([]);
    }
    //console.log(uniquePipelineNames);

    return (
        <Modal

            open={props.open}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Customize map
                </Typography>
                <Box sx={{mt: 2, ml: 1}}>
                    <FormControl sx={{m: 1, minWidth: 220}} size="small">
                        <InputLabel>Sub Projects</InputLabel>
                        <Select
                            value={subProjectId}
                            label="Sub Projects"
                            input={<OutlinedInput label="Name"/>}
                            style={{backgroundColor: 'white'}}
                            onChange={subProjectChange}
                        >
                            <MenuItem value={""}>All</MenuItem>
                            {subProjects.map(row => (
                                <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, width: 219}} size="small">
                        <InputLabel>Tags</InputLabel>
                        <MUIMultiSelect
                            setSelected={props.setPipelineSelected}
                            selected={props.pipelineSelected}
                            handleChange={handlePipelineFilterChange}
                            options={uniquePipelineNames || []}
                        />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 220}} size="small">
                        <InputLabel>Services</InputLabel>
                        <Select
                            value={""}
                            label="Services"
                            style={{backgroundColor: 'white'}}
                            //onChange={handlePipelineFilterChange}
                        >
                            <MenuItem value={""}>All</MenuItem>
                            <MenuItem value={'Production'}>Production</MenuItem>
                            <MenuItem value={'Source Water'}>Source Water</MenuItem>
                            <MenuItem value={'Water Injection'}>Water Injection</MenuItem>
                            <MenuItem value={'Water disposal (Dammam)'}>Water disposal (Dammam)</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 220}} size="small">
                        <InputLabel>Age</InputLabel>
                        <Select
                            value={""}
                            label="Age"
                            style={{backgroundColor: 'white'}}
                            //onChange={handlePipelineFilterChange}
                        >
                            <MenuItem value={""}>All</MenuItem>
                            <MenuItem value={'<=5'}> {'<=5 years'}</MenuItem>
                            <MenuItem value={'>5_and_<=10'}>{'>5 and <=10 years'}</MenuItem>
                            <MenuItem value={'>10_and_<=20'}>{'>10 and <=20 years'}</MenuItem>
                            <MenuItem value={'>20'}>{'>20 years'}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 220}} size="small">
                        <InputLabel>FLST date</InputLabel>
                        <Select
                            value={""}
                            label="FLST date"
                            style={{backgroundColor: 'white'}}
                            //onChange={handlePipelineFilterChange}
                        >
                            <MenuItem value={""}>All</MenuItem>

                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 220}} size="small">
                        <InputLabel>Risk</InputLabel>
                        <Select
                            value={""}
                            label="Risk"
                            style={{backgroundColor: 'white'}}
                            //onChange={handlePipelineFilterChange}
                        >
                            <MenuItem value={""}>All</MenuItem>

                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 220}} size="small">
                        <InputLabel>PoF</InputLabel>
                        <Select
                            value={""}
                            label="PoF"
                            style={{backgroundColor: 'white'}}
                            //onChange={handlePipelineFilterChange}
                        >
                            <MenuItem value={""}>All</MenuItem>

                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 220}} size="small">
                        <InputLabel>CoF</InputLabel>
                        <Select
                            value={""}
                            label="CoF"
                            style={{backgroundColor: 'white'}}
                            //onChange={handlePipelineFilterChange}
                        >
                            <MenuItem value={""}>All</MenuItem>

                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 220}} size="small">
                        <InputLabel>Flow Rate</InputLabel>
                        <Select
                            value={""}
                            label="Flow Rate"
                            style={{backgroundColor: 'white'}}
                            //onChange={handlePipelineFilterChange}
                        >
                            <MenuItem value={""}>All</MenuItem>

                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 220}} size="small">
                        <InputLabel>Failure Impact</InputLabel>
                        <Select
                            value={""}
                            label="Failure Impact"
                            style={{backgroundColor: 'white'}}
                            //onChange={handlePipelineFilterChange}
                        >
                            <MenuItem value={""}>All</MenuItem>

                        </Select>
                    </FormControl>
                </Box>
                <Card sx={{minWidth: 275, m: 1}}>
                    <CardContent>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Show</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="female" control={<Radio/>}
                                                  label="High Consequence Areas (HCA)"/>
                                <FormControlLabel value="male" control={<Radio/>} label="Soil Moisture Content"/>
                                <FormControlLabel value="other" control={<Radio/>} label="Replaced Lines"/>
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                </Card>
            </Box>
        </Modal>
    )
}